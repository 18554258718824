const pageWrapperTarget = document.querySelector(".pageWrapper");

// Adding a animated follow the mouse glow effect throughout the page
pageWrapperTarget.addEventListener("mousemove", (e) => {
  const rect = pageWrapperTarget.getBoundingClientRect();
  const left = e.clientX - rect.x;
  const top = e.clientY - rect.y;

  pageWrapperTarget.style.setProperty("--left", `${left}px`);
  pageWrapperTarget.style.setProperty("--top", `${top}px`);
});

// const pageWrapperTarget = document.querySelector(".pageWrapper");
// const beforeElement = pageWrapperTarget.querySelector("spotLight");

// function showHideElement(show) {
//   if (beforeElement) beforeElement.style.display = show ? "block" : "none";
// }

// console.log({ pageWrapperTarget, beforeElement });
// // Adding a animated follow the mouse glow effect throughout the page
// if (pageWrapperTarget && beforeElement) {
//   pageWrapperTarget.addEventListener("mousemove", (e) => {
//     const rect = pageWrapperTarget.getBoundingClientRect();
//     const left = e.clientX - rect.x;
//     const top = e.clientY - rect.y;

//     beforeElement.style.setProperty("--left", `${left}px`);
//     beforeElement.style.setProperty("--top", `${top}px`);

//     const cursorPositionThreshold = 400;

//     if (e.clientX > cursorPositionThreshold) {
//       showHideElement(false);
//     } else {
//       showHideElement(true);
//     }
//   });
// }

// // window.addEventListener("mousemove", hideElement);

// // Set the cursor position threshold
// const cursorPositionThreshold = 100;

// // Hide the element if the cursor is more than the threshold away from the left edge of the page wrapper
// function checkCursorPosition() {
//   const cursorPosition = window.pageXOffset;
//   if (cursorPosition > cursorPositionThreshold) {
//     showHideElement();
//   }
// }

// // setInterval(checkCursorPosition, 100); // Check the cursor position every 100 milliseconds
